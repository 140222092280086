import { useRef, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

interface CameraComponentProps {
  onPhotoTaken: (photoBlob: Blob) => void;
  onExit: () => void;
}

export function CameraComponent({ onPhotoTaken, onExit }: CameraComponentProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [shutterOverlay, setShutterOverlay] = useState(false);
  const [photoCount, setPhotoCount] = useState(0); // State to track the number of photos taken

  const startCameraStream = async () => {
    const maxTimeout = 5000;
    const startTime = Date.now();

    const waitForVideoRef = () => {
      return new Promise<void>((resolve, reject) => {
        const checkInterval = 100;
        const interval = setInterval(() => {
          if (Date.now() - startTime > maxTimeout) {
            clearInterval(interval);
            reject(new Error("Operation timed out: Video element not available."));
          }

          if (videoRef.current) {
            clearInterval(interval);
            resolve();
          }
        }, checkInterval);
      });
    };

    const waitForLoadedMetadata = () => {
      return new Promise<void>((resolve, reject) => {
        const timeoutId = setTimeout(
          () => {
            reject(new Error("Operation timed out: Metadata not loaded within 5 seconds."));
          },
          maxTimeout - (Date.now() - startTime),
        );

        videoRef.current!.onloadedmetadata = () => {
          clearTimeout(timeoutId);
          videoRef.current?.play();
          resolve();
        };
      });
    };

    await waitForVideoRef();

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: { ideal: "environment" } },
    });
    videoRef.current!.srcObject = stream;
    await waitForLoadedMetadata();
    return stream;
  };

  const {
    data: stream,
    error,
    isLoading,
    refetch: tryAgain,
  } = useQuery({
    queryKey: ["cameraStream"],
    queryFn: () => startCameraStream(),
    retry: 0,
  });

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.onloadedmetadata = () => {
        videoRef.current?.play();
        setIsCameraReady(true);
      };
    } else {
      if (videoRef.current) {
        videoRef.current.srcObject = null; // Reset video if no stream
      }
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  const handleTakePhoto = () => {
    const video = videoRef.current!;
    const canvas = canvasRef.current!;
    const context = canvas.getContext("2d")!;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(
      (blob) => {
        if (blob) {
          onPhotoTaken(blob);
        }
      },
      "image/jpeg",
      0.95,
    );

    // Increment photo count
    setPhotoCount((prevCount) => prevCount + 1);

    // Trigger shutter effect
    setShutterOverlay(true);
    setTimeout(() => {
      setShutterOverlay(false);
    }, 200); // Shutter effect duration
  };

  useEffect(() => {
    const handleVolumeChange = () => {
      handleTakePhoto();
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("volumechange", handleVolumeChange);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("volumechange", handleVolumeChange);
      }
    };
  }, []);

  return (
    <div className="absolute inset-0 z-10 h-full w-full bg-keenDarkBg">
      {isLoading && <div>Loading camera stream...</div>}
      {error && (
        <div className="p-5 text-center text-base text-red-500">
          {error instanceof Error ? error.message : "An error occurred"}
          <button onClick={() => tryAgain()}>Retry</button>
        </div>
      )}
      {!error && (
        <video ref={videoRef} autoPlay playsInline muted className="h-auto w-full" onClick={handleTakePhoto} />
      )}
      <canvas ref={canvasRef} className="hidden" />
      {isCameraReady && (
        <>
          <button
            onClick={handleTakePhoto}
            className="absolute bottom-[30px] left-[calc(50%+35px)] h-[70px] w-[70px] -translate-x-1/2 transform cursor-pointer rounded-full border-[5px] border-white bg-white shadow-[0_0_10px_rgba(0,0,0,0.5)]"
          ></button>
          {/* Display the photo count to the right of the shutter button */}
          <div className="text-shadow absolute bottom-[50px] left-[calc(50%+100px)]  text-3xl text-white">
            {photoCount || ""}
          </div>
        </>
      )}
      <button
        onClick={onExit}
        className="absolute bottom-[30px] left-[10%] h-[30px] w-[70px] -translate-x-1/2 transform cursor-pointer rounded-[15px] border-none bg-[#f00] text-white shadow-[0_0_10px_rgba(0,0,0,0.5)]"
      >
        EXIT
      </button>

      {/* Shutter overlay effect */}
      {shutterOverlay && <div className="animate-fade-out absolute inset-0 z-20 bg-black opacity-80"></div>}
    </div>
  );
}
